import React, { Fragment } from 'react';
import CommentBox from '../../CommentBox.js';

const Contact = (props) => {
    return (
        <div className="contact" id="contactID">
            <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.75 0H2.75C1.2375 0 0.01375 1.2375 0.01375 2.75L0 19.25C0 20.7625 1.2375 22 2.75 22H24.75C26.2625 22 27.5 20.7625 27.5 19.25V2.75C27.5 1.2375 26.2625 0 24.75 0ZM2.75 5.5L13.75 12.375L24.75 5.5V19.25H2.75V5.5ZM2.75 2.75L13.75 9.625L24.75 2.75H2.75Z" fill="#282C34" />
            </svg>

            <h3>{props.title}</h3>
            <p>{props.text}</p>
            <CommentBox />

            <div className="end_notes">
                <a href="https://linkedin.com/in/dodie-matthew.com" target="_blank"><svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.375 0H10.625C4.75787 0 0 4.75787 0 10.625V40.375C0 46.2421 4.75787 51 10.625 51H40.375C46.2443 51 51 46.2421 51 40.375V10.625C51 4.75787 46.2443 0 40.375 0ZM17 40.375H10.625V17H17V40.375ZM13.8125 14.3055C11.7598 14.3055 10.0938 12.6267 10.0938 10.557C10.0938 8.48725 11.7598 6.8085 13.8125 6.8085C15.8652 6.8085 17.5312 8.48725 17.5312 10.557C17.5312 12.6267 15.8674 14.3055 13.8125 14.3055ZM42.5 40.375H36.125V28.4665C36.125 21.3095 27.625 21.8514 27.625 28.4665V40.375H21.25V17H27.625V20.7506C30.5915 15.2554 42.5 14.8495 42.5 26.0121V40.375Z" fill="#4A4A4A" />
                </svg>
                </a>

                <a href="https://github.com/dodiematts" target="_blank" >
                    <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9948 0C17.5331 0.00320627 12.2507 1.9546 8.09225 5.5052C3.93378 9.0558 1.17047 13.974 0.296524 19.3803C-0.577427 24.7866 0.49497 30.3284 3.32194 35.0145C6.1489 39.7006 10.5461 43.2255 15.727 44.9587C16.8776 45.1696 17.2973 44.4592 17.2973 43.8475C17.2973 43.2999 17.2773 41.8487 17.2661 39.9236C10.8699 41.3203 9.51704 36.8321 9.51704 36.8321C8.47045 34.1654 6.95853 33.4575 6.95853 33.4575C4.86935 32.0255 7.11844 32.056 7.11844 32.056C7.84745 32.1568 8.54385 32.4234 9.15451 32.8352C9.76516 33.2471 10.2739 33.7934 10.642 34.4324C12.6936 37.9601 16.0269 36.9403 17.3373 36.3494C17.4424 35.1796 17.9604 34.0859 18.798 33.265C13.6898 32.6829 8.31934 30.705 8.31934 21.8632C8.28604 19.5712 9.13413 17.3544 10.6876 15.6728C9.98489 13.683 10.0658 11.4994 10.9138 9.5674C10.9138 9.5674 12.8439 8.94684 17.2397 11.9318C21.0098 10.8971 24.9878 10.8971 28.7579 11.9318C33.1505 8.94844 35.0742 9.5674 35.0742 9.5674C35.9233 11.4989 36.0057 13.6825 35.3044 15.6728C36.8581 17.354 37.7049 19.5714 37.6687 21.8632C37.6687 30.7259 32.2878 32.6757 27.1644 33.2482C27.9903 33.9602 28.7259 35.3697 28.7259 37.5208C28.7259 40.6052 28.6971 43.093 28.6971 43.8491C28.6971 44.4648 29.112 45.1824 30.2802 44.9579C35.4609 43.2229 39.8571 39.6962 42.6825 35.0086C45.5079 30.3211 46.5782 24.7785 45.7019 19.3722C44.8256 13.966 42.0599 9.04861 37.8994 5.49973C33.739 1.95086 28.4552 0.00195454 22.9932 0.00160264L22.9948 0Z" fill="#4A4A4A" />
                    </svg>
                </a>
                <p><b>Brooklyn,NY 11234</b><br></br><b>dodiematts@gmail.com</b></p>
            </div>
        </div>
    )
}

export default Contact;